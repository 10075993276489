/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.


const awsmobile = {
  "aws_project_region": "ap-southeast-2",
  "aws_cloud_logic_custom": [
      {
          "name": "AdminQueries",
          "endpoint": "https://yfi623wekf.execute-api.ap-southeast-2.amazonaws.com/tecc",
          "region": "ap-southeast-2"
      }
  ],
  "aws_appsync_graphqlEndpoint": "https://pmynnenoezcavlpmgmuyb2pgqa.appsync-api.ap-southeast-2.amazonaws.com/graphql",
  "aws_appsync_region": "ap-southeast-2",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_cognito_identity_pool_id": "ap-southeast-2:a03b7d04-6a31-4065-a28a-0753917a46ca",
  "aws_cognito_region": "ap-southeast-2",
  "aws_user_pools_id": "ap-southeast-2_kVD5UR9Ug",
  "aws_user_pools_web_client_id": "45kbp65rn9ukgjl5bn5k2aq8p2",
  "oauth": {},
  "aws_cognito_username_attributes": [
      "EMAIL"
  ],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [
      "EMAIL",
      "FAMILY_NAME",
      "GIVEN_NAME",
      "PHONE_NUMBER"
  ],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [
      "SMS"
  ],
  "aws_cognito_password_protection_settings": {
      "passwordPolicyMinLength": 8,
      "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
      "EMAIL"
  ],
  "aws_user_files_s3_bucket": "intellitrackstorage91253-tecc",
  "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;